// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apoyo-soporte-jsx": () => import("./../../../src/pages/apoyo-soporte.jsx" /* webpackChunkName: "component---src-pages-apoyo-soporte-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-hseq-jsx": () => import("./../../../src/pages/hseq.jsx" /* webpackChunkName: "component---src-pages-hseq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mision-jsx": () => import("./../../../src/pages/mision.jsx" /* webpackChunkName: "component---src-pages-mision-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-nuestros-valores-jsx": () => import("./../../../src/pages/nuestros-valores.jsx" /* webpackChunkName: "component---src-pages-nuestros-valores-jsx" */),
  "component---src-pages-operacion-mantenimiento-jsx": () => import("./../../../src/pages/operacion-mantenimiento.jsx" /* webpackChunkName: "component---src-pages-operacion-mantenimiento-jsx" */),
  "component---src-pages-politicas-calidad-jsx": () => import("./../../../src/pages/politicas-calidad.jsx" /* webpackChunkName: "component---src-pages-politicas-calidad-jsx" */),
  "component---src-pages-post-jsx": () => import("./../../../src/pages/post.jsx" /* webpackChunkName: "component---src-pages-post-jsx" */)
}

